<template>
  <div>
    <h5 class="is-size-5 has-text-centered">
      {{ doc.name }}
    </h5>

    <div class="columns">
      <div class="column">
        <a-card :editable="false">
          <a-autocomplete
            field="name"
            options="operators"
            @select="fillOperatorToDoc"
          />

          <a-taginput
            field="operators"
            options="operators"
          />

          <contacts
            v-if="keys(doc.contacts).length"
          />
        </a-card>
      </div>

      <div class="column">
        <a-card :editable="false">
          <a-input
            field="officialName"
          />

          <a-input
            field="address"
          />

          <a-input
            field="city"
          />

          <a-input
            field="zip"
          />

          <a-autocomplete
            field="country"
            :options="countries"
            option-label="country"
          />

          <a-input
            field="regNr"
          />

          <a-input
            field="vatNr"
          />
        </a-card>
      </div>
    </div>

    <add-button />
  </div>
</template>

<script>
import { keys } from 'lodash'
import Contacts from '@/components/Contacts/Contacts'
import formMixin from './formMixin'
import AddButton from './Customer/AddButton'


export default {
  components: {
    AddButton,
    Contacts
  },
  mixins: [formMixin],
  computed: {
    countries () {
      return _.sortBy(_.uniqBy(this.$store.getters['customers/items'], 'country'), 'country')
    }
  },
  methods: {
    keys,
    fillOperatorToDoc (operator) {
      let customer = _.clone(this.$store.getters['operators/data'][operator.id])
      customer.id = this.doc.id
      this.$store.dispatch(`customers/set`, customer)
    }
  }
}
</script>
